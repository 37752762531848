import { useHistory, useLocation } from 'react-router-dom';
import { UCFStore, getInternalPortalUrl } from 'ucf-lib';
import queryString from 'query-string';

export const pathList = [
  {
    path: '/dashboard',
    title: 'Dashboard',
    isRoot: true,
  },
  {
    path: '/products/all',
    title: 'Product List',
    isRoot: true,
  },
  {
    path: '/products/add',
    title: 'Add Product',
    isRoot: false,
  },
  {
    path: '/orders/purchase-order-summary',
    title: 'Order',
    isRoot: true,
  },
  {
    path: '/chat',
    title: 'Chat',
    isRoot: true,
  },
  {
    path: '/settings',
    title: 'Setting',
    isRoot: true,
  },
];

export function navigateTo(store: UCFStore, history: any, path: string, yPosition = 0): void {
  setCurrentPath(store, path);
  store.navigation.setIsBack(false);
  store.navigation.setPageRefreshed(false);
  history.push(path);
}

export function navigateBack(store: UCFStore, history: any): void {
  store.navigation.setIsBack(true);
  history.goBack();
}

export function setCurrentPath(store: UCFStore, path: string): void {
  store.navigation.setCurrentPage(path);
}

export function setCurrentYPosition(store: UCFStore, yPosition: number): void {
  store.UI.lastYPosition = yPosition;
}

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function getUrlQueryString(history: any) {
  return queryString.parse(history.location.search);
}

export function setUrlQueryString(history: any, newKey: string, newValue: any) {
  if (window && window.location) {
    let queries = queryString.parse(history.location.search);
    if (newValue !== '') {
      queries = {
        ...queries,
        [newKey]: newValue,
      };
      console.log(`Setting url query string ${queryString.stringify(queries)}`);
      setUrl(history, queryString.stringify(queries));
    }
  }
}

export function setUrl(history: any, urlWithQuery: string) {
  // console.log('location ', location);
  history.replace(history.location.pathname + `?${urlWithQuery}`);
}

export function getPreviewCPARUrl(id: string): string {
  return getInternalPortalUrl() + `print/ccf?id=${id}`;
}

import React from 'react';
import { Text } from '../../Components/Common/Text';
import { COLOR, backgroundImageStyle } from 'ucf-lib';
import { ResponsiveView } from '../Common/ResponsiveView';
import { ImageIcon } from '../Common/ImageIcon';
import Logo from '../../Assets/Images/logo.png';
import { getFontStyle } from '../../Helpers/LayoutHelper';

export function WelcomeMessage(): JSX.Element {
  return (
    <ResponsiveView id="ucf-login-page-welcome-view" phoneStyle={styles.phoneContainer} style={styles.container}>
      <ImageIcon image={Logo} size={500} style={{ height: 200, padding: 10 }} />
      <Text style={styles.adminText}>CUSTOMER PORTAL</Text>
      <Text style={styles.journeyText}>Almighty Customer Portal</Text>
    </ResponsiveView>
  );
}

const styles = {
  phoneContainer: {
    flexDirection: 'column',
    width: '100%',
    background: COLOR.WHITE,
    padding: 0,
  } as React.CSSProperties,
  container: {
    flexDirection: 'column',
    height: '100%',
    width: '50%',
    background: COLOR.WHITE,
    justifyContent: 'center',
    alignItems: 'center',
  } as React.CSSProperties,
  adminText: {
    ...getFontStyle({ fontSize: 25, weight: 'bold', color: COLOR.DARK_BLUE }),
    textAlign: 'center',
    marginTop: 5,
    marginBottom: 10,
  } as React.CSSProperties,
  journeyText: {
    ...getFontStyle({ fontSize: 14, color: COLOR.DARK_BLACK }),
    marginTop: 5,
    marginBottom: 10,
  } as React.CSSProperties,
};

import { Menu, MenuItem } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { COLOR, SHADOW } from 'ucf-lib';
import { getBottomBorderStyle, getFontStyle } from '../../Helpers/LayoutHelper';
import { navigateTo, pathList } from '../../Helpers/NavigationHelper';
import { useStore } from '../../Store/UseStore';
import { Icon } from '../Common/Icon';
import { IconButton } from '../Common/IconButton';
import { ResponsiveView } from '../Common/ResponsiveView';
import { Text } from '../Common/Text';
import { View } from '../Common/View';

export const MobileTopBar = observer(() => {
  const history = useHistory();
  const store = useStore();
  const isRootPage = () => {
    const page = pathList.find((p) => p.path === store.UI.currentPath);
    if (page) {
      return page.isRoot;
    }
    return false;
  };
  const getPageTitle = () => {
    const page = pathList.find((p) => p.path === store.UI.currentPath);
    return page?.title;
  };

  const { mobileTopBar } = store.UI;

  return (
    <ResponsiveView id="ucf-mobile-top-bar-view" phoneStyle={styles.container} webStyle={{ display: 'none' }}>
      <ResponsiveView
        id="ucf-mobile-top-bar"
        style={{
          // @ts-ignore
          zIndex: '1300',
          ...styles.bar,
        }}>
        <View style={styles.titleSection}>
          {mobileTopBar.canBack && (
            <IconButton
              name="arrow_back_ios_new"
              style={styles.titleIcon}
              onClick={() => history.goBack()}></IconButton>
          )}
          <Text style={styles.titleText}>{mobileTopBar.title}</Text>
        </View>
        {mobileTopBar.actionComponent}
      </ResponsiveView>
    </ResponsiveView>
  );
});

const styles = {
  container: {
    paddingTop: 50,
  } as React.CSSProperties,
  bar: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 40,
    padding: 10,
    position: 'fixed',
    width: '95%',
    top: 0,
    ...getBottomBorderStyle(),
    ...SHADOW.STANDARD(1),
    backgroundColor: COLOR.WHITE,
  } as React.CSSProperties,
  titleSection: {
    justifyContent: 'flex-start',
    alignItems: 'center',
  } as React.CSSProperties,
  titleIcon: {
    width: 25,
    color: COLOR.DARK_BLACK,
    fontSize: 25,
    marginRight: 10,
  } as React.CSSProperties,
  titleText: {
    ...getFontStyle({ weight: 'bold', fontSize: 16 }),
  } as React.CSSProperties,
  rightBar: {
    paddingRight: 10,
    justifyContent: 'flex-end',
    alignItems: 'center',
  } as React.CSSProperties,
  rightIcon: {
    width: 25,
    paddingRight: 5,
    paddingLeft: 5,
    color: COLOR.GREY,
    fontSize: 28,
  } as React.CSSProperties,
};

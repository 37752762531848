export class LocalStorageService {
  static saveToken(data: any) {
    localStorage.setItem('ucf-cust-token', JSON.stringify(data));
  }
  static getToken() {
    const token = localStorage.getItem('ucf-cust-token');
    return token ? JSON.parse(token) : null;
  }
  static removeToken() {
    return localStorage.removeItem('ucf-cust-token');
  }
}

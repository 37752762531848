import { InputAdornment, makeStyles, TextField, Input, OutlinedInput } from '@material-ui/core';
import React from 'react';
import { COLOR } from 'ucf-lib';
import { getFontStyle } from '../../Helpers/LayoutHelper';
import { Icon } from './Icon';
import { IconButton } from './IconButton';
import { ResponsiveView } from './ResponsiveView';
import { Text } from './Text';

type Props = {
  label: string;
  name: string;
  value: any;
  type?: string;
  isMultiline?: boolean;
  disabled?: boolean;
  id?: string;
  style?: React.CSSProperties;
  hasIconLeft?: boolean;
  iconLeft?: string;
  iconLeftClicked?: () => void;
  hasIconRight?: boolean;
  iconRight?: string;
  iconRightClicked?: () => void;
  hasBorder?: boolean;
  hasLabelOutside?: boolean;
  labelOutside?: string;
  labelOutsideStyle?: React.CSSProperties;
  isRequired?: boolean;
  hasAdditionalElement?: boolean;
  additionalElement?: React.ReactNode;
  textAlign?: 'center' | 'right';
  isNumber?: boolean;
  isAllowComma?: boolean;
  onEnter?: () => void;
  onChange: (name: string, value: string) => void;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onFocus?: () => void;
  onLeave?: () => void;
};

const useStyles = makeStyles(() => ({
  root: {
    borderBottomColor: COLOR.GREY,
    '& .MuiInput-underline:before': {
      borderBottomColor: COLOR.GREY,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: COLOR.DARK_BLACK,
    },
    '& .Mui-disabled': {
      backgroundColor: COLOR.SOFT_GREY,
      paddingTop: 12,
      paddingBottom: 12,
    },
  },
}));

const useOutlineStyles = makeStyles(() => ({
  root: {
    borderColor: COLOR.GREY,
    '& .MuiOutlinedInput-root:before': {
      borderColor: COLOR.GREY,
    },
    '& .MuiOutlinedInput-root:after': {
      borderColor: COLOR.DARK_BLACK,
    },
    '& .Mui-focused': {
      borderColor: COLOR.DARK_BLACK,
    },
    '& .Mui-disabled': {
      backgroundColor: COLOR.SOFT_GREY,
      paddingTop: 12,
      paddingBottom: 12,
    },
  },
}));

export function TextInput({
  label,
  name,
  value,
  id,
  style,
  type,
  isMultiline,
  disabled,
  hasIconLeft,
  iconLeft = '',
  iconLeftClicked,
  hasIconRight,
  iconRight = '',
  iconRightClicked,
  hasBorder,
  hasLabelOutside,
  labelOutside,
  labelOutsideStyle,
  isRequired,
  hasAdditionalElement,
  additionalElement,
  textAlign,
  isNumber,
  isAllowComma,
  onEnter,
  onChange,
  onClick,
  onFocus,
  onLeave,
}: Props): JSX.Element {
  const inputChanged = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    onChange(e.target.name, e.target.value);
  };

  const classes = useStyles();
  const outlineClasses = useOutlineStyles();

  const renderOutline = () => {
    return (
      <OutlinedInput
        id={id}
        key={id}
        className={outlineClasses.root}
        type={type || 'text'}
        multiline={isMultiline}
        rows={3}
        disabled={disabled}
        placeholder={label}
        value={value}
        name={name}
        onChange={(e) => {
          if (isNumber) {
            if (!/^[0-9]*\.?(?:\d{1,2})?$/.test(e.target.value)) {
              return;
            }
          }
          if (isAllowComma) {
            if (!/^[,0-9]*\.?(?:\d{1,2})?$/.test(e.target.value)) {
              return;
            }
          }
          inputChanged(e);
          e.preventDefault();
          e.stopPropagation();
        }}
        onFocus={() => {
          if (onFocus) {
            onFocus();
          }
        }}
        onBlur={() => {
          if (onLeave) {
            onLeave();
          }
        }}
        style={{ ...style }}
        startAdornment={
          hasIconLeft ? (
            <InputAdornment position="start">
              <IconButton
                name={iconLeft}
                style={{ color: COLOR.GREY, cursor: iconLeftClicked ? 'pointer' : 'default' }}
                onClick={() => {
                  if (iconLeftClicked) {
                    iconLeftClicked();
                  }
                }}
              />
            </InputAdornment>
          ) : undefined
        }
        endAdornment={
          hasIconRight ? (
            <InputAdornment position="end" disablePointerEvents={disabled}>
              <IconButton
                name={iconRight}
                style={{ color: COLOR.GREY, cursor: iconRightClicked ? 'pointer' : 'default' }}
                onClick={() => {
                  if (iconRightClicked) {
                    iconRightClicked();
                  }
                }}
              />
            </InputAdornment>
          ) : undefined
        }
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            if (onEnter) {
              onEnter();
            }
          }
        }}
        onMouseDown={onClick}
      />
    );
  };

  const renderInput = () => {
    return (
      <TextField
        id={id}
        className={classes.root}
        style={{ marginTop: 10, marginBottom: 10, ...style }}
        type={type || 'text'}
        multiline={isMultiline}
        rows={3}
        disabled={disabled}
        placeholder={label}
        value={value}
        name={name}
        InputProps={{
          startAdornment: hasIconLeft ? (
            <IconButton
              name={iconLeft}
              style={{ color: COLOR.GREY, cursor: iconLeftClicked ? 'pointer' : 'default' }}
              onClick={() => {
                if (iconLeftClicked) {
                  iconLeftClicked();
                }
              }}
            />
          ) : undefined,
          endAdornment: hasIconRight ? (
            <InputAdornment position="end">
              <IconButton
                name={iconRight}
                style={{ color: COLOR.GREY, cursor: iconRightClicked ? 'pointer' : 'default' }}
                onClick={() => {
                  if (iconRightClicked) {
                    iconRightClicked();
                  }
                }}
              />
            </InputAdornment>
          ) : undefined,
          style: {
            textAlign: textAlign ? (textAlign === 'center' ? 'center' : 'right') : 'left',
          },
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            if (onEnter) {
              onEnter();
            }
          }
        }}
        onChange={(e) => {
          if (isNumber) {
            if (!/^[0-9]*\.?(?:\d{1,2})?$/.test(e.target.value)) {
              return;
            }
          }
          inputChanged(e);
        }}
      />
    );
  };

  const renderTextInput = () => {
    return hasBorder ? renderOutline() : renderInput();
  };

  return hasLabelOutside ? (
    <ResponsiveView id={`ucf-input-label-view-${id}`} style={{ ...styles.labelOutContainer, ...labelOutsideStyle }}>
      <ResponsiveView id={`ucf-input-label-row-view-${id}`} style={styles.labelOutRow}>
        <Text style={styles.labelOutside}>{labelOutside}</Text>
        {isRequired && <Text style={styles.labelRequired}>*</Text>}
        {hasAdditionalElement && additionalElement}
      </ResponsiveView>
      {renderTextInput()}
    </ResponsiveView>
  ) : (
    renderTextInput()
  );
}

const styles = {
  labelOutContainer: {
    flexDirection: 'column',
  } as React.CSSProperties,
  labelOutRow: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 5,
  } as React.CSSProperties,
  labelOutside: {
    ...getFontStyle({ fontSize: 12 }),
  } as React.CSSProperties,
  labelRequired: {
    ...getFontStyle({ fontSize: 12, color: COLOR.RED }),
  } as React.CSSProperties,
};

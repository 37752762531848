import Button from '@material-ui/core/Button';
import React from 'react';
import { COLOR } from 'ucf-lib';
import { Icon } from '../Icon';
import { Touchable } from '../Touchable';

type Props = {
  id?: string;
  label: string;
  style?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  outlined?: boolean;
  onPress: () => void;
  options?: {
    hasIconLeft?: boolean;
    iconLeft?: string;
    iconLeftStyle?: React.CSSProperties;
    hasIconRight?: boolean;
    iconRight?: string;
    iconRightStyle?: React.CSSProperties;
  };
};

export function AppButton({ id, label, style, containerStyle, outlined, onPress, options = {} }: Props): JSX.Element {
  const { hasIconLeft, hasIconRight, iconLeft, iconRight, iconLeftStyle, iconRightStyle } = options;
  return (
    <Touchable style={containerStyle} onClick={async () => onPress()}>
      <Button
        id={id}
        variant={outlined ? 'outlined' : 'contained'}
        style={{
          marginTop: 10,
          marginBottom: 10,
          backgroundColor: outlined ? COLOR.WHITE : COLOR.DARK_BLUE,
          color: outlined ? COLOR.DARK_BLUE : COLOR.WHITE,
          borderColor: outlined ? COLOR.DARK_BLUE : undefined,
          ...style,
        }}
        startIcon={hasIconLeft ? <Icon name={iconLeft || ''} style={{ ...iconLeftStyle }} /> : undefined}
        endIcon={hasIconRight ? <Icon name={iconRight || ''} style={{ ...iconRightStyle }} /> : undefined}>
        {label}
      </Button>
    </Touchable>
  );
}

AppButton.displayName = 'AppButton';

import React, { useState, useEffect } from 'react';
import { useStore } from './Store/UseStore';
import { observer } from 'mobx-react';
import { AppRouter } from './Router/AppRouter';
import { LoadingBar } from './Components/Loading/LoadingBar';
import { isDev } from 'ucf-lib';
import { LocalStorageService } from './Services/LocalStorageService';
import { Toast } from './Components/Common/Toast';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { IAuthUser } from 'ucf-lib/dist/Interface/IAuth';

const App = observer(() => {
  const [loaded, setLoaded] = useState(false);
  const store = useStore();

  useEffect(() => {
    (async () => {
      const user: IAuthUser = LocalStorageService.getToken();
      if (user) {
        store.user.setToken(user);
        store.user.setAuth();
      }
      setLoaded(true);
    })();
  }, []);

  return (
    <>
      {store.UI.loading && <LoadingBar />}
      <Toast />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>{loaded ? <AppRouter /> : null}</MuiPickersUtilsProvider>
    </>
  );
});

export default App;

import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { COLOR } from 'ucf-lib';
import { ResponsiveView } from '../Common/ResponsiveView';
import { Text } from '../Common/Text';
import { getCardStyle, getFontStyle } from '../../Helpers/LayoutHelper';
import { TextInput } from '../Common/TextInput';
import { useStore } from '../../Store/UseStore';
import { AppButton } from '../Common/Button/AppButton';
import Logo from '../../Assets/Images/logo.png';
import { ImageIcon } from '../Common/ImageIcon';
import { navigateTo, useQuery } from '../../Helpers/NavigationHelper';
import { useHistory } from 'react-router-dom';
import { TextButton } from '../Common/Button/TextButton';

export const SignUpPage = observer(() => {
  const store = useStore();
  const history = useHistory();
  const query = useQuery();

  const data = store.user.signupBody;

  return (
    <ResponsiveView phoneStyle={styles.phoneContainer} webStyle={styles.container}>
      <>
        <ImageIcon image={Logo} size={300} style={{ height: 200, marginBottom: 10 }} />
        <ResponsiveView style={styles.titleRow}>
          <Text style={styles.title}>Please enter following information to join with us.</Text>
        </ResponsiveView>
        <ResponsiveView style={styles.inputContainer} webStyle={{ width: '30%' }}>
          <TextInput
            id="ucf-sign-up-email-input"
            label="Email"
            style={styles.input}
            name=""
            value={data.email}
            onChange={(name, value) => {
              data.email = value;
            }}
          />
          <TextInput
            id="ucf-sign-up-password-input"
            label="Password"
            type="password"
            style={styles.input}
            name=""
            value={data.password}
            onChange={(name, value) => {
              data.password = value;
            }}
          />
          <TextInput
            id="ucf-sign-up-first-name-input"
            label="First Name"
            style={styles.input}
            name=""
            value={data.firstName}
            onChange={(name, value) => {
              data.firstName = value;
            }}
          />
          <TextInput
            id="ucf-sign-up-last-name-input"
            label="Last Name"
            style={styles.input}
            name=""
            value={data.lastName}
            onChange={(name, value) => {
              data.lastName = value;
            }}
          />
          <TextInput
            id="ucf-sign-up-phone-number-input"
            label="Phone Number"
            style={styles.input}
            name=""
            value={data.phoneNumber}
            onChange={(name, value) => {
              data.phoneNumber = value;
            }}
          />
          <AppButton
            label="Submit"
            onPress={async () => {
              const signup = await store.user.signup();
              if (signup) {
                history.replace('/login');
              }
            }}
          />
        </ResponsiveView>
      </>
    </ResponsiveView>
  );
});

const styles = {
  container: {
    width: '100vw',
    height: '100vh',
    background: COLOR.WHITE,
    justifyContent: 'center',
    alignItems: 'center',
  } as React.CSSProperties,
  phoneContainer: {
    padding: 20,
    height: '100vh',
    width: '90vw',
    background: COLOR.WHITE,
    justifyContent: 'center',
  } as React.CSSProperties,
  titleRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 20,
  } as React.CSSProperties,
  title: {
    ...getFontStyle({ fontSize: 14, color: COLOR.DARK_BLACK, weight: 'bold' }),
    textAlign: 'center',
  } as React.CSSProperties,
  inputContainer: {
    ...getCardStyle(20),
  } as React.CSSProperties,
  input: {
    height: 40,
    fontSize: 12,
    marginBottom: 10,
  } as React.CSSProperties,
};

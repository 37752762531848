import React, { useEffect, useState } from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { LoadingBar } from '../Components/Loading/LoadingBar';
import { useStore } from '../Store/UseStore';

interface PrivateRouteProps extends RouteProps {
  // tslint:disable-next-line:no-any
  component?: any;
}

interface PrivateRouteState {
  loading: boolean;
}

export const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, ...rest } = props;
  const store = useStore();
  const [state, setState] = useState<PrivateRouteState>({ loading: true });

  useEffect(() => {
    setTimeout(() => {
      setState({ loading: false });
    }, 2000);
  }, []);

  return (
    <Route
      {...rest}
      render={(props) =>
        state.loading ? (
          <LoadingBar />
        ) : store.user.isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

import React from 'react';
import { Icon } from './Icon';
import { Touchable } from './Touchable';

type Props = {
  name: string;
  id?: string;
  style?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  selected?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

export function IconButton({ id, style, containerStyle, onClick, name, selected }: Props): JSX.Element {
  return (
    <Touchable
      id={id}
      onClick={onClick}
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        ...containerStyle,
      }}>
      <Icon name={name} style={style} selected={selected} />
    </Touchable>
  );
}

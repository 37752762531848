import React from 'react';
import { observer } from 'mobx-react';
import { COLOR } from 'ucf-lib';
import { WelcomeMessage } from './WelcomeMessage';
import { LoginPanel } from './LoginPanel';
import { ResponsiveView } from '../Common/ResponsiveView';

export const LoginPage = observer(() => {
  return (
    <ResponsiveView id="ucf-login-page-view" phoneStyle={styles.phoneContainer} style={styles.container}>
      <WelcomeMessage />
      <LoginPanel />
    </ResponsiveView>
  );
});

const styles = {
  container: {
    width: '100vw',
    height: '100vh',
    flexDirection: 'row',
    background: COLOR.WHITE,
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
    overflow: 'hidden',
  } as React.CSSProperties,
  phoneContainer: {
    height: '100vh',
    width: '90vw',
    flexDirection: 'column',
    background: COLOR.WHITE,
    justifyContent: 'center',
    alignItems: 'center',
  } as React.CSSProperties,
};

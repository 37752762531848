import React from 'react';
import { COLOR, FONT } from 'ucf-lib';

type Props = {
  children: React.ReactNode;
  id?: string;
  style?: React.CSSProperties;
  selectable?: boolean;
  onClick: () => void;
};

export function TextButton({ children, id, style, selectable, onClick }: Props): JSX.Element {
  return (
    <p
      id={id}
      style={{
        cursor: 'pointer',
        userSelect: selectable ? 'all' : 'none',
        fontFamily: FONT.MAIN,
        color: COLOR.DARK_BLACK,
        ...style,
      }}
      onClick={onClick}>
      {children}
    </p>
  );
}

import React from 'react';
import { COLOR, FONT } from 'ucf-lib';

type Props = {
  children: React.ReactNode;
  id?: string;
  style?: React.CSSProperties;
  selectable?: boolean;
};

export function Text({ children, id, style, selectable }: Props): JSX.Element {
  return (
    <p
      id={id}
      style={{ userSelect: selectable ? 'all' : 'none', fontFamily: FONT.MAIN, color: COLOR.DARK_BLACK, ...style }}>
      {children}
    </p>
  );
}

import React, { useState } from 'react';
import { Text } from '../../Components/Common/Text';
import { useStore } from '../../Store/UseStore';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { COLOR, CARD } from 'ucf-lib';
import { TextInput } from '../Common/TextInput';
import { AppButton } from '../Common/Button/AppButton';
import { ResponsiveView } from '../Common/ResponsiveView';
import { LocalStorageService } from '../../Services/LocalStorageService';
import { navigateTo } from '../../Helpers/NavigationHelper';
import { TextButton } from '../Common/Button/TextButton';
import { getFontStyle } from '../../Helpers/LayoutHelper';

interface State {
  passwordVisible: boolean;
}

export const LoginPanel = observer(() => {
  const store = useStore();
  const history = useHistory();
  const [state, setState] = useState<State>({ passwordVisible: false });
  const { email, password } = store.user.loginBody;

  const login = async () => {
    const loggedIn = await store.user.login();
    if (loggedIn) {
      store.user.setAuth(true);
      LocalStorageService.saveToken(store.user.user);
      history.replace('/complaints/list');
    }
  };

  return (
    <ResponsiveView phoneStyle={styles.phoneContainer} webStyle={styles.container}>
      <ResponsiveView style={styles.loginCard}>
        <Text style={styles.loginText}>LOG IN</Text>
        <TextInput
          id="ucf-login-email-input"
          label="Email Address"
          name="email"
          hasIconLeft
          iconLeft="mail_outline"
          value={email}
          onChange={(name, value) => {
            store.user.loginBody.email = value;
          }}
        />
        <TextInput
          id="ucf-login-password-input"
          label="Password"
          name="password"
          type={state.passwordVisible ? 'text' : 'password'}
          hasIconLeft
          iconLeft="lock"
          hasIconRight
          iconRight={state.passwordVisible ? 'visibility' : 'visibility_off'}
          iconRightClicked={() => {
            setState({ passwordVisible: !state.passwordVisible });
          }}
          value={password}
          onChange={(name, value) => {
            store.user.loginBody.password = value;
          }}
          onEnter={async () => login()}
        />
        <ResponsiveView style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
          <TextButton
            id="ucf-forget-password-link"
            style={{ ...getFontStyle({ fontSize: 14, color: COLOR.DARK_BLUE }), textDecoration: 'underline' }}
            onClick={() => navigateTo(store, history, '/forgot-password')}>
            Forgot your password?
          </TextButton>
        </ResponsiveView>
        <AppButton id="ucf-login-button" label="LOGIN" onPress={async () => login()} />
        <TextButton
          id="ucf-sign-up-link"
          style={{ ...getFontStyle({ fontSize: 14, color: COLOR.DARK_BLUE }), textDecoration: 'underline' }}
          onClick={() => navigateTo(store, history, '/signup')}>
          {`Don't have account yet?`}
        </TextButton>
      </ResponsiveView>
    </ResponsiveView>
  );
});

const styles = {
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: COLOR.WHITE,
    alignItems: 'center',
    height: '100%',
    width: '100%',
  } as React.CSSProperties,
  phoneContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: COLOR.WHITE,
    height: '100%',
    width: '100%',
  } as React.CSSProperties,
  loginCard: {
    ...CARD.STANDARD(1),
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: 220,
    padding: 30,
  } as React.CSSProperties,
  loginText: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
  } as React.CSSProperties,
};

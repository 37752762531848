import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { UCFStore } from 'ucf-lib';
import { StoreProvider } from './Store/ProviderStore';
import { setupApp } from './Setup';
declare module '*.png';
declare module '*.jpg';

setupApp();
const ucfStore = new UCFStore();

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider value={ucfStore}>
      <App />
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { lazy } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';
import { AppRoute } from './AppRoute';
import { LoginPage } from '../Components/Auth/LoginPage';
import { SignUpPage } from '../Components/Auth/SignUpPage';
import { VerifyAccountPage } from '../Components/Auth/VerifyAccountPage';
import { ForgotPasswordPage } from '../Components/Auth/ForgotPasswordPage';
import { ResetPasswordPage } from '../Components/Auth/ResetPasswordPage';

export const AppRouter = () => {
  return (
    <Router>
      <Switch>
        <Redirect exact path="/" to="/complaints/list" />
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/signup" component={SignUpPage} />
        <Route exact path="/forgot-password" component={ForgotPasswordPage} />
        <Route exact path="/reset-password" component={ResetPasswordPage} />
        <Route exact path="/account/verify-email" component={VerifyAccountPage} />
        <PrivateRoute component={AppRoute} />
      </Switch>
    </Router>
  );
};

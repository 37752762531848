import { Snackbar } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { COLOR } from 'ucf-lib';
import { getCardStyle, getFontStyle } from '../../Helpers/LayoutHelper';
import { useStore } from '../../Store/UseStore';
import { Icon } from './Icon';
import { ResponsiveView } from './ResponsiveView';
import { Text } from './Text';

const backgroundColor = {
  success: COLOR.GREEN,
  fail: COLOR.AMBER,
  error: COLOR.RED,
};

const iconName = {
  success: 'check_circle_outline',
  fail: 'warning',
  error: 'error_outline',
};

export const Toast = observer(() => {
  const store = useStore();

  const handleClose = () => {
    store.UI.appShowMessage = false;
  };

  return (
    <Snackbar
      id="ucf-toast-message-view"
      key="ucf-toast-message-view"
      open={store.UI.appShowMessage}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={handleClose}>
      <ResponsiveView style={{ ...styles.container, backgroundColor: backgroundColor[store.UI.appState] }}>
        <Icon name={iconName[store.UI.appState]} style={styles.icon} />
        <Text style={styles.message}>{store.UI.appMessage}</Text>
      </ResponsiveView>
    </Snackbar>
  );
});

const styles = {
  container: {
    ...getCardStyle(10),
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  } as React.CSSProperties,
  message: {
    ...getFontStyle({ color: COLOR.WHITE }),
    paddingLeft: 10,
  } as React.CSSProperties,
  icon: {
    color: COLOR.WHITE,
  } as React.CSSProperties,
};

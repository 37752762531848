import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { COLOR } from 'ucf-lib';
import { ResponsiveView } from '../Common/ResponsiveView';
import { Text } from '../Common/Text';
import { getCardStyle, getFontStyle } from '../../Helpers/LayoutHelper';
import { useStore } from '../../Store/UseStore';
import Logo from '../../Assets/Images/logo.png';
import { ImageIcon } from '../Common/ImageIcon';
import { navigateTo, useQuery } from '../../Helpers/NavigationHelper';
import { useHistory } from 'react-router-dom';
import { TextButton } from '../Common/Button/TextButton';

export const VerifyAccountPage = observer(() => {
  const store = useStore();
  const history = useHistory();
  const query = useQuery();
  const memberId = query.get('id') || '';
  const [verified, setVerified] = useState(false);

  useEffect(() => {
    (async () => {
      if (memberId !== '') {
        const verify = await store.user.verifyEmail(memberId);
        setVerified(verify);
      }
    })();
  }, []);

  return verified ? (
    <ResponsiveView phoneStyle={styles.phoneContainer} webStyle={styles.container}>
      <ImageIcon image={Logo} size={300} style={{ height: 200, marginBottom: 10 }} />
      <ResponsiveView style={styles.titleRow}>
        <Text style={styles.title}>
          Congratulations, your account has been activated, please login using your account.
        </Text>
      </ResponsiveView>
      <TextButton
        id="ucf-login-link"
        style={{ ...getFontStyle({ fontSize: 14, color: COLOR.DARK_BLUE }), textDecoration: 'underline' }}
        onClick={() => navigateTo(store, history, '/login')}>
        Go to Login
      </TextButton>
    </ResponsiveView>
  ) : (
    <></>
  );
});

const styles = {
  container: {
    width: '100vw',
    height: '100vh',
    background: COLOR.WHITE,
    justifyContent: 'center',
    alignItems: 'center',
  } as React.CSSProperties,
  phoneContainer: {
    padding: 20,
    height: '100vh',
    width: '90vw',
    background: COLOR.WHITE,
    justifyContent: 'center',
  } as React.CSSProperties,
  titleRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 20,
  } as React.CSSProperties,
  title: {
    ...getFontStyle({ fontSize: 14, color: COLOR.DARK_BLACK, weight: 'bold' }),
    textAlign: 'center',
  } as React.CSSProperties,
  inputContainer: {
    ...getCardStyle(20),
  } as React.CSSProperties,
  input: {
    height: 40,
    fontSize: 12,
    marginBottom: 10,
  } as React.CSSProperties,
};

import { initAppConfig } from 'ucf-lib';

export const setupApp = (): void => {
  type BuildType = 'UAT' | 'BETA' | 'PRODUCTION';
  let buildType: BuildType = 'UAT';
  if (process.env.NODE_ENV === 'production') {
    buildType = process.env.REACT_APP_BUILD_TYPE as any;
  }
  initAppConfig('web', buildType);
};

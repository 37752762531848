import React from 'react';
import { COLOR, SHADOW } from 'ucf-lib';
import { ResponsiveView } from '../Common/ResponsiveView';
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import { Icon } from '../Common/Icon';
import { useHistory } from 'react-router-dom';
import { TabMenuList, BottomTabMenuList } from './MenuConfig';
import { getTopBorderStyle } from '../../Helpers/LayoutHelper';
import { navigateTo } from '../../Helpers/NavigationHelper';
import { useStore } from '../../Store/UseStore';
import { observer } from 'mobx-react';

export const BottomMenu = observer(() => {
  const history = useHistory();
  const store = useStore();

  const tabIcon = (menu: TabMenuList) => {
    return (
      <BottomNavigationAction
        key={`bottom-tab-${menu.label}`}
        label={menu.label}
        icon={<Icon name={menu.icon} selected={menu.key === store.UI.mobileActiveMenu} />}
        style={{ fontSize: 12, color: menu.key === store.UI.mobileActiveMenu ? COLOR.GOLD : COLOR.DARK_GREY }}
        onClick={() => {
          navigateTo(store, history, menu.path);
        }}
      />
    );
  };

  return (
    <ResponsiveView id="ucf-mobile-bottom-tab-bar" phoneStyle={styles.container} webStyle={{ display: 'none' }}>
      <ResponsiveView style={styles.bar}>
        <BottomNavigation showLabels>{BottomTabMenuList.map((tab) => tabIcon(tab))}</BottomNavigation>
      </ResponsiveView>
    </ResponsiveView>
  );
});

const styles = {
  container: {
    paddingBottom: 60,
    backgroundColor: COLOR.WHITE,
  } as React.CSSProperties,
  bar: {
    position: 'fixed',
    bottom: 0,
    height: 60,
    width: '100vw',
    justifyContent: 'center',
    alignItems: 'center',
    ...getTopBorderStyle(),
    ...SHADOW.STANDARD(1),
    backgroundColor: COLOR.WHITE,
  } as React.CSSProperties,
};

import React, { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, useHistory } from 'react-router-dom';
import { ResponsiveView } from '../Components/Common/ResponsiveView';
import { BottomMenu } from '../Components/Menu/BottomMenu';
import { SideMenu } from '../Components/Menu/SideMenu';
import { TopBar } from '../Components/Menu/TopBar';
import { useStore } from '../Store/UseStore';

import { setCurrentPath } from '../Helpers/NavigationHelper';
import { MobileTopBar } from '../Components/Menu/MobileTopBar';
import { LoadingBar } from '../Components/Loading/LoadingBar';
import { lazyComponentLoader } from '../Helpers/LayoutHelper';

// use lazy load for each component
const ComplaintPage = lazy(() => lazyComponentLoader(import('../Components/Complaint/ComplaintPage')));
const ComplaintDetailPage = lazy(() => lazyComponentLoader(import('../Components/Complaint/ComplaintDetailPage')));

export function AppRoute(): JSX.Element {
  const store = useStore();
  const history = useHistory();
  useEffect(() => {
    setCurrentPath(store, history.location.pathname);
  }, [history.location]);
  return (
    <Router>
      <ResponsiveView id="ucf-app-container" phoneStyle={styles.phoneContainer} webStyle={styles.container}>
        <TopBar />
        <MobileTopBar />
        <ResponsiveView
          id="ucf-content-container"
          phoneStyle={styles.phoneContentContainer}
          webStyle={styles.contentContainer}>
          {/* <SideMenu /> */}
          <ResponsiveView id="ucf-content" phoneStyle={styles.phoneContent} webStyle={styles.content}>
            <Suspense fallback={<LoadingBar />}>
              <Switch>
                <Route path="/complaints/list" key="complaint-list" component={ComplaintPage} />
                <Route path="/complaints/detail" key="complaint-detail" component={ComplaintDetailPage} />
              </Switch>
            </Suspense>
          </ResponsiveView>
        </ResponsiveView>
        <BottomMenu />
      </ResponsiveView>
    </Router>
  );
}

const styles = {
  container: {
    position: 'relative',
    flexDirection: 'column',
    maxWidth: '100%',
    minHeight: '100vh',
    overflow: 'hidden',
  } as React.CSSProperties,
  phoneContainer: {
    width: '100%',
    flexDirection: 'column',
    overflow: 'hidden',
  } as React.CSSProperties,
  contentContainer: {
    // height: 'calc(100% - 50px)',
    // overflowY: 'auto',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  } as React.CSSProperties,
  phoneContentContainer: {
    // width: '100vw',
    position: 'relative',
    height: '85%',
    flexDirection: 'column',
  } as React.CSSProperties,
  phoneContent: {
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    paddingTop: 10,
  } as React.CSSProperties,
  content: {
    width: '100%',
    overflowX: 'auto',
    // overflowY: 'scroll',
    flexDirection: 'column',
    // width: '100vw',
    padding: 20,
  } as React.CSSProperties,
};

import { ClickAwayListener, MenuList, Paper, Popper } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { COLOR } from 'ucf-lib';
import { LocalStorageService } from '../../Services/LocalStorageService';
import { useStore } from '../../Store/UseStore';
import { Icon } from '../Common/Icon';
import { IconButton } from '../Common/IconButton';
import { ImageIcon } from '../Common/ImageIcon';
import { ResponsiveView } from '../Common/ResponsiveView';
import { Text } from '../Common/Text';
import { View } from '../Common/View';
import Logo from '../../Assets/Images/logo.png';
import { getFontStyle } from '../../Helpers/LayoutHelper';
import { TextButton } from '../Common/Button/TextButton';
import { observer } from 'mobx-react';

export const TopBar = observer(() => {
  const store = useStore();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = async () => {
    setAnchorEl(null);
  };
  const logout = () => {
    store.user.logout();
    LocalStorageService.removeToken();
    history.replace('/login');
    window.location.reload();
  };
  return (
    <ResponsiveView
      id="ucf-top-bar-view"
      phoneStyle={{ display: 'none' }}
      webStyle={{
        // @ts-ignore
        zIndex: '1300',
        ...styles.container,
      }}>
      <ResponsiveView id="ucf-top-bar" style={styles.containerBar}>
        <View style={styles.company}>
          <ImageIcon image={Logo} size={300} style={{ height: 60, width: 50, padding: 10 }} />
          <Text style={styles.companyText}>CUSTOMER COMPLAINTS PORTAL</Text>
        </View>
        <ResponsiveView style={styles.rightBar}>
          <Text style={styles.companyText}>Hi, {store.user.user.fullName}</Text>
          <IconButton name="account_circle" style={styles.rightIcon} onClick={handleClick}></IconButton>
          <Popper
            id="ucf-top-nav-popup-right-bar"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            style={{
              // @ts-ignore
              zIndex: '1300',
            }}>
            <Paper>
              <ClickAwayListener onClickAway={() => handleClose()}>
                <MenuList>
                  <ResponsiveView id="ucf-top-nav-bar-logout-button" style={styles.menuContainer}>
                    <Icon style={styles.icon} name="logout" />
                    <p
                      style={{
                        ...styles.caption,
                        marginLeft: 10,
                        cursor: 'pointer',
                      }}
                      id="ucf-logout-button"
                      onClick={() => {
                        logout();
                      }}>
                      Logout
                    </p>
                  </ResponsiveView>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Popper>
        </ResponsiveView>
      </ResponsiveView>
    </ResponsiveView>
  );
});

const styles = {
  container: {
    paddingTop: 60,
  } as React.CSSProperties,
  containerBar: {
    position: 'fixed',
    overflowX: 'auto',
    overflowY: 'hidden',
    width: '100vw',
    top: 0,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 50,
    padding: 10,
    backgroundColor: COLOR.DARK_BLUE,
  } as React.CSSProperties,
  company: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: COLOR.DARK_BLUE,
  } as React.CSSProperties,
  companyText: {
    fontSize: 18,
    color: COLOR.WHITE,
  } as React.CSSProperties,
  rightBar: {
    paddingRight: 20,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: COLOR.DARK_BLUE,
  } as React.CSSProperties,
  rightIcon: {
    paddingRight: 5,
    paddingLeft: 5,
    color: COLOR.WHITE,
    fontSize: 30,
  } as React.CSSProperties,
  caption: {
    textDecoration: 'none',
    fontFamily: 'Roboto',
    ...getFontStyle({ fontSize: 16, color: COLOR.DARK_GREY }),
  } as React.CSSProperties,
  icon: {
    fontSize: 20,
    color: COLOR.DARK_GREY,
  } as React.CSSProperties,
  menuContainer: {
    padding: 10,
    width: 150,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  } as React.CSSProperties,
};

import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { COLOR } from 'ucf-lib';
import { ResponsiveView } from '../Common/ResponsiveView';
import { Text } from '../Common/Text';
import { getCardStyle, getFontStyle } from '../../Helpers/LayoutHelper';
import { useStore } from '../../Store/UseStore';
import Logo from '../../Assets/Images/logo.png';
import { ImageIcon } from '../Common/ImageIcon';
import { navigateTo, useQuery } from '../../Helpers/NavigationHelper';
import { useHistory } from 'react-router-dom';
import { TextInput } from '../Common/TextInput';
import { AppButton } from '../Common/Button/AppButton';

interface State {
  passwordVisible: boolean;
}

interface ResetState {
  password: string;
  confirmPassword: string;
}

export const ResetPasswordPage = observer(() => {
  const store = useStore();
  const history = useHistory();
  const query = useQuery();
  const userId = query.get('id') || '';
  const [reset, setReset] = useState<ResetState>({ password: '', confirmPassword: '' });
  const [state, setState] = useState<State>({ passwordVisible: false });

  const resetPassword = async () => {
    const submitReset = await store.user.sendResetPassword({ ...reset, id: userId });
    if (submitReset) {
      navigateTo(store, history, '/login');
    }
  };

  return (
    <ResponsiveView phoneStyle={styles.phoneContainer} webStyle={styles.container}>
      <ImageIcon image={Logo} size={300} style={{ height: 200, marginBottom: 10 }} />
      <ResponsiveView style={styles.titleRow}>
        <Text style={styles.title}>Please enter and confirm your new password before submit</Text>
      </ResponsiveView>
      <TextInput
        id="ucf-reset-password-input"
        label="Password"
        name="password"
        type={state.passwordVisible ? 'text' : 'password'}
        hasIconLeft
        iconLeft="lock"
        hasIconRight
        iconRight={state.passwordVisible ? 'visibility' : 'visibility_off'}
        iconRightClicked={() => {
          setState({ passwordVisible: !state.passwordVisible });
        }}
        value={reset.password}
        onChange={(name, value) => {
          setReset({ ...reset, password: value });
        }}
      />
      <TextInput
        id="ucf-reset-confirm-password-input"
        label="Confirm Password"
        name="password"
        type={state.passwordVisible ? 'text' : 'password'}
        hasIconLeft
        iconLeft="lock"
        hasIconRight
        iconRight={state.passwordVisible ? 'visibility' : 'visibility_off'}
        iconRightClicked={() => {
          setState({ passwordVisible: !state.passwordVisible });
        }}
        value={reset.confirmPassword}
        onChange={(name, value) => {
          setReset({ ...reset, confirmPassword: value });
        }}
      />
      <AppButton id="ucf-reset-button" label="RESET" onPress={async () => resetPassword()} />
    </ResponsiveView>
  );
});

const styles = {
  container: {
    width: '100vw',
    height: '100vh',
    background: COLOR.WHITE,
    justifyContent: 'center',
    alignItems: 'center',
  } as React.CSSProperties,
  phoneContainer: {
    padding: 20,
    height: '100vh',
    width: '90vw',
    background: COLOR.WHITE,
    justifyContent: 'center',
  } as React.CSSProperties,
  titleRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 20,
  } as React.CSSProperties,
  title: {
    ...getFontStyle({ fontSize: 14, color: COLOR.DARK_BLACK, weight: 'bold' }),
    textAlign: 'center',
  } as React.CSSProperties,
  inputContainer: {
    ...getCardStyle(20),
  } as React.CSSProperties,
  input: {
    height: 40,
    fontSize: 12,
    marginBottom: 10,
  } as React.CSSProperties,
};

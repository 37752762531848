import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { COLOR } from 'ucf-lib';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
      '& .MuiLinearProgress-indeterminate': {
        backgroundColor: COLOR.DARK_BLUE,
      },
      '& .MuiLinearProgress-barColorPrimary': {
        backgroundColor: COLOR.LIGHT_GREY,
      },
    },
  }),
);

export function LoadingBar() {
  const classes = useStyles();
  return (
    <div
      className={classes.root}
      style={{
        position: 'fixed',
        left: 0,
        top: 0,
        // @ts-ignore
        zIndex: '1500',
      }}>
      <LinearProgress />
    </div>
  );
}

import React from 'react';
import { COLOR } from 'ucf-lib';

type Props = {
  children?: React.ReactNode;
  id?: string;
  style?: React.CSSProperties;
  className?: string;
};

export function View({ children, id, style, className }: Props): JSX.Element {
  return (
    <div
      id={id}
      className={className}
      style={{ backgroundColor: COLOR.WHITE, height: '100%', margin: 0, display: 'flex', ...style }}>
      {children}
    </div>
  );
}

import React from 'react';
import { ResponsiveView } from '../Common/ResponsiveView';

type Props = {
  style?: React.CSSProperties;
  image: string;
  size: number;
};

export function ImageIcon({ style, image, size }: Props): JSX.Element {
  return (
    <ResponsiveView style={{ justifyContent: 'center', alignItems: 'center', ...style }}>
      <ResponsiveView
        style={{
          backgroundImage: `url('${image}')`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          height: size,
          width: size,
        }}></ResponsiveView>
    </ResponsiveView>
  );
}

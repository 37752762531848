export interface MenuList {
  key: string;
  label: string;
  isParent: boolean;
  icon: string;
  child?: MenuChild[];
  path?: string;
}

export interface MenuChild {
  key: string;
  label: string;
  path: string;
}

export interface TabMenuList {
  key: string;
  label: string;
  path: string;
  icon: string;
}

export const SideMenuList: MenuList[] = [
  {
    key: 'dashboard',
    label: 'Dashboard',
    path: '/dashboard',
    isParent: true,
    icon: 'dashboard',
  },
  {
    key: 'complaints',
    label: 'Customer Complaints',
    isParent: true,
    icon: 'shopping_bag',
    child: [
      {
        key: 'complaint-list',
        label: 'List',
        path: '/complaints/list',
      },
    ],
  },
];

export const BottomTabMenuList: TabMenuList[] = [
  {
    key: 'dashboard',
    label: 'Home',
    path: '/dashboard',
    icon: 'dashboard',
  },
  {
    key: 'complaint-list',
    label: 'Complaints',
    path: '/complaints/list',
    icon: 'shopping_bag',
  },
];
